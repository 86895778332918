var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-navigation-drawer',{attrs:{"app":""},model:{value:(_vm.drawerOpen),callback:function ($$v) {_vm.drawerOpen=$$v},expression:"drawerOpen"}},[_c('v-list',{attrs:{"three-line":"","subheader":""}},[_c('v-subheader',[_vm._v("Menu")]),_vm._l((_vm.menuItems),function(item){return [_c('v-list-item',{key:item.name,attrs:{"to":item.route}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.desc))])],1)],1),_c('v-divider',{key:item.name})]})],2)],1):_vm._e(),_c('v-toolbar',{attrs:{"height":_vm.nav_bar_height}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-divider',{attrs:{"vertical":""}}):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawerOpen = !_vm.drawerOpen}}}):_vm._e(),_c('v-toolbar-title',{class:{
				'ml-0': _vm.$vuetify.breakpoint.smAndDown,
				'ml-3': _vm.$vuetify.breakpoint.mdAndUp,
			}},[_c('router-link',{staticClass:"font-weight-medium",class:{
					title: _vm.$vuetify.breakpoint.lgAndDown,
					'display-1': _vm.$vuetify.breakpoint.lgAndUp,
				},attrs:{"to":"/","tag":"button"}},[_vm._v(" "+_vm._s(_vm.appTitle)+" ")])],1),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down"},[_vm._l((_vm.menuItems),function(item){return [_c('v-btn',{key:item.name,class:{
						title: _vm.$vuetify.breakpoint.lgAndUp,
						subheading: _vm.$vuetify.breakpoint.lgAndDown,
					},attrs:{"text":"","to":item.route}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-divider',{key:item.name,attrs:{"vertical":""}})]}),_c('v-btn',{class:{
					title: _vm.$vuetify.breakpoint.lgAndUp,
					subheading: _vm.$vuetify.breakpoint.lgAndDown,
				},attrs:{"text":"","href":"https://showyourstripes.info"}},[_vm._v(" Climate Stripes ")]),_c('v-divider',{attrs:{"vertical":""}})],2),_c('a',{attrs:{"target":"_blank","rel":"noopener","href":"https://www.derby.ac.uk/"}},[_c('img',{staticClass:"mx-3 mt-2 logo",attrs:{"alt":"University of Derby logo","src":require("@/assets/img/UoD_Logo.png")}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }