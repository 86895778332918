<template lang="html">
	<div class="top-container">
		<v-tabs slider-color="#ff6347" centered v-model="selectedTab" class="tabs">
			<v-tab
				v-for="imgType in imgTypeObj"
				:key="imgType.id"
				:link="true"
				:href="`#${imgType.id}`"
				@change="tabSelected(imgType.id)"
				:disabled="tweening !== null"
			>
				{{ imgType.tabText }}
			</v-tab>
		</v-tabs>
		<img
			class="stripe-image"
			:class="{
				main: isSelected(imgType.id),
				hidden: !isSelected(imgType.id) && !isPrevious(imgType.id),
				tweening: imgType.id === tweening,
			}"
			:src="publicPath + imgType.imgSource"
			:alt="`biodiversity stripes for ${imageSettings.name}`"
			v-for="(imgType, i) in imgTypeObj"
			:key="imgType.id"
		/>
		<img
			class="stripe-image last-image"
			v-if="lastUrl"
			:src="publicPath + lastUrl"
		/>
		<div class="download-button-styles">
			<v-tooltip top v-if="sharing">
				<template #activator="{ on }">
					<v-btn
						:small="$vuetify.breakpoint.xsOnly"
						color="#ff6347"
						fab
						v-on="on"
						@click="shareFacebook()"
					>
						<v-icon>facebook</v-icon>
					</v-btn>
				</template>
				<span class="tool-tip">Share to facebook</span>
			</v-tooltip>
			<v-tooltip top v-if="sharing">
				<template #activator="{ on }">
					<v-btn
						:small="$vuetify.breakpoint.xsOnly"
						color="#ff6347"
						fab
						v-on="on"
						@click="shareTwitter()"
					>
						<img src="@/assets/img/x_logo.svg" class="twitter-logo" />
					</v-btn>
				</template>
				<span class="tool-tip">Share to twitter</span>
			</v-tooltip>
			<v-tooltip top v-if="sharing">
				<template #activator="{ on }">
					<v-btn
						:small="$vuetify.breakpoint.xsOnly"
						color="#ff6347"
						fab
						v-on="on"
						@click="shareLinkedIn()"
					>
						<v-icon>mdi-linkedin</v-icon>
					</v-btn>
				</template>
				<span class="tool-tip">Share to LinkedIn</span>
			</v-tooltip>
			<v-tooltip top v-if="sharing">
				<template #activator="{ on }">
					<v-btn
						:small="$vuetify.breakpoint.xsOnly"
						color="#ff6347"
						fab
						v-on="on"
						@click="downloadImage(mainUrl)"
					>
						<v-icon>file_download</v-icon>
					</v-btn>
				</template>
				<span class="tool-tip">Download</span>
			</v-tooltip>
			<v-tooltip top>
				<template #activator="{ on }">
					<v-btn
						:small="$vuetify.breakpoint.xsOnly"
						color="#ff6347"
						fab
						v-on="on"
						@click="share()"
					>
						<v-icon>{{ sharing ? 'mdi-close' : 'mdi-menu' }}</v-icon>
					</v-btn>
				</template>
				<span class="tool-tip">{{ sharing ? 'Hide options' : 'Options' }}</span>
			</v-tooltip>
		</div>
		<div class="next-up">
			<div class="play-button-container">
				<v-tooltip top>
					<template #activator="{ on }">
						<v-btn
							x-small
							:class="{ on: $store.playing }"
							color="#ff6347"
							v-on="on"
							@click="togglePlay"
						>
							<v-icon>{{ $store.playing ? 'mdi-pause' : 'mdi-play' }}</v-icon>
						</v-btn>
					</template>
					<span class="tool-tip">{{
						$store.playing ? 'Stop slideshow' : 'Play slideshow'
					}}</span>
				</v-tooltip>
			</div>
			<p>
				{{ nextText }}
			</p>
		</div>
	</div>
</template>

<script>
import gsap from 'gsap'
const ANIMATION_MS = 750

export default {
	name: 'DisplayStripes',
	props: {
		imageSettings: {
			required: true,
			type: Object,
		},
	},
	data() {
		return {
			alertVisible: false,
			sharing: false,
			tweening: null,
			previous: null,
			lastUrl: null,
			publicPath: '',
			// publicPath: process.env.NODE_ENV === 'production' ? '/showyournaturestripes' : '',
		}
	},
	methods: {
		togglePlay() {
			this.$store.playing = !this.$store.playing
			if (this.$store.playing) {
				this.$store.countdown = 6
			}
		},
		tabSelected(tab) {
			this.$store.playing = false
			this.previous = this.$store.selectedVariant
			this.tweening = tab
			this.$nextTick(() => {
				const tl = gsap.timeline({
					onComplete: () => {
						this.tweening = null
						this.previous = null
					},
				})
				tl.to('.tweening', { width: '100%', duration: ANIMATION_MS / 1000 })
				tl.set('.tweening', { width: '' })
			})
		},
		isSelected(id) {
			return (
				this.$store.selectedVariant === id ||
				(this.$store.selectedVariant === '' && id === 'basic')
			)
		},
		isPrevious(id) {
			return (
				this.previous === id ||
				(this.previous === '' && id === 'basic') ||
				(this.previous === 'basic' && id === '')
			)
		},
		downloadImage(url) {
			this.dialog = false
			const link = document.createElement('a')
			link.href = url
			// Make the filename just the final part of the URL (don't need the `/stripes/` part)
			const filename = url.split('/').pop()
			link.setAttribute('download', filename)
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		},
		share() {
			this.sharing = !this.sharing
			this.$store.playing = false
		},
		shareFacebook() {
			const link = document.createElement('a')
			const url = encodeURIComponent(window.location.href)
			link.href = `https://www.facebook.com/sharer/sharer.php?u=${url}`
			link.target = '_blank'
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
			this.sharing = false
		},
		shareTwitter() {
			const link = document.createElement('a')
			const url = encodeURIComponent(window.location.href)
			link.href = `https://x.com/intent/tweet?url=${url}`
			link.target = '_blank'
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
			this.sharing = false
		},
		shareLinkedIn() {
			const link = document.createElement('a')
			const url = encodeURIComponent(window.location.href)
			link.href = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`
			link.target = '_blank'
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
			this.sharing = false
		},
	},
	computed: {
		currentWidth() {
			return '50px'
		},
		nextText() {
			if (!this.$store.playing) {
				return 'Click to start the slideshow'
			} else {
				return `Next image: ${this.$store.nextImage} in ${this.$store.countdown}s`
			}
		},
		selectedTab: {
			set(tab) {
				this.$store.selectedVariant = tab
				const params = {
					region: this.$route.params.region || this.$store.selectedRegion,
				}
				if (tab !== 'basic') {
					params.variant = tab
				}
				this.$router.push({
					name: 'stripes',
					params,
				})
			},
			get() {
				return this.$store.selectedVariant || 'basic'
			},
		},
		imgTypeObj() {
			const tabs = [
				{
					tabText: this.imageSettings.name,
					id: 'basic',
					imgSource: this.imageSettings.url,
				},
			]
			if (Object.keys(this.imageSettings.variants).length > 0) {
				for (let v in this.imageSettings.variants) {
					const variant = this.imageSettings.variants[v]
					tabs.push({
						tabText: `with ${variant.name}`,
						id: variant.name,
						imgSource: variant.url,
					})
				}
			}
			return tabs
		},
		mainUrl() {
			let mainUrl
			if (
				this.$store.selectedVariant &&
				this.$store.selectedVariant !== '' &&
				this.$store.selectedVariant !== 'basic'
			) {
				mainUrl = this.imageSettings.variants[this.$store.selectedVariant].url
			} else {
				mainUrl = this.imageSettings.url
			}
			if (this.lastUrl === null) {
				this.lastUrl = mainUrl
			}
			return mainUrl
		},
	},
	watch: {
		mainUrl(newval, oldval) {
			if (oldval === this.lastUrl) {
				gsap.set('.main', {
					width: '0',
					maskImage:
						'linear-gradient(to right,rgba(0, 0, 0, 1) 0%,rgba(0, 0, 0, 1) 90% transparent 100%)',
				})
				this.$nextTick(() => {
					const tl = gsap.timeline({
						onComplete: () => {
							this.lastUrl = newval
						},
					})
					tl.set('.main', {
						width: '0',
						maskImage:
							'linear-gradient(to right,rgba(0, 0, 0, 1) 0%,rgba(0, 0, 0, 1) 90% transparent 100%)',
						zIndex: 15,
					})
					tl.set('.last-image', { width: '100%', zIndex: 12 })
					tl.to('.main', { width: '100%', duration: ANIMATION_MS / 1000 })
					tl.set('.last-image', { width: '', zIndex: '' })
					tl.set('.main', { width: '', zIndex: '', maskImage: '' })
				})
			} else {
				this.lastUrl = newval
			}
		},
	},
}
</script>

<style lang="css" scoped>
.top-container {
	max-width: 1000px;
	width: 100%;
	position: relative;
	display: grid;
	grid-template-rows: 48px 1fr 24px;
	grid-template-areas: 'tabs' 'image' 'next';
	overflow: hidden;
}

.tabs {
	grid-area: tabs;
}

.next-up {
	grid-area: next;
	display: flex;
	justify-content: center;
	align-items: center;
}

.next-up p {
	font-size: 0.8rem;
	margin: 0 0 0 4px;
}

.stripe-image {
	grid-area: image;
	border-width: 2px;
	border-style: groove;
	padding: 0;
	display: flex;
	flex-direction: column;
	top: 48px;
	overflow: hidden;
	height: 100%;
	width: 100%;
	opacity: 1;
	object-fit: cover;
	object-position: left;
	border-right: none;
	z-index: 3;
}

.stripe-image.last-image,
.stripe-image.hidden {
	width: 0;
}

.stripe-image.tweening {
	width: 0;
	z-index: 10;
	mask-image: linear-gradient(
		to right,
		rgba(0, 0, 0, 1) 0%,
		rgba(0, 0, 0, 1) 90% transparent 100%
	);
}

.caption {
	background-color: white;
	padding-bottom: 0.25rem;
}

.caption img {
	margin-bottom: -0.25rem;
}

.stripe-container .stripe-image {
	width: 100%;
	margin: 0;
	padding: 0;
	min-width: 310px;
}

.tool-tip {
	font-size: 16px;
}
.download-button-styles {
	position: absolute;
	bottom: 58px;
	right: 35px;
	z-index: 20;
}

button {
	margin-left: 0.5rem;
}

.twitter-logo {
	width: 16px;
}

button.on {
	background-color: rgb(170, 66, 47) !important;
	box-shadow: 0px 0px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%),
		0 1px 18px 0 rgb(0 0 0 / 12%);
	transform: translate(0px, 1px);
}

@media (max-width: 545px) {
	>>> .v-tabs__item {
		font-size: 0.85em;
	}
}

@media (max-width: 480px) {
	>>> .v-tabs__item {
		font-size: 0.75em;
	}
}

@media (max-width: 435px) {
	>>> .v-tabs__item {
		font-size: 0.7em;
	}
}

@media (max-width: 415px) {
	>>> .v-tabs__item {
		font-size: 0.62em;
	}
}
</style>
