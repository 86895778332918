var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-text',{staticClass:"pa-2 text-center"},[_c('span',{class:{
				'd-inline': _vm.$vuetify.breakpoint.mdAndUp,
				'd-block caption': _vm.$vuetify.breakpoint.smAndDown,
			}},[_vm._v(" Interested in studying biology and zoology?  ")]),_c('a',{attrs:{"target":"_blank","href":"https://www.derby.ac.uk/undergraduate/biology-zoology-courses/"}},[_c('span',{class:{
					'd-inline': _vm.$vuetify.breakpoint.mdAndUp,
					'd-block caption': _vm.$vuetify.breakpoint.smAndDown,
				}},[_vm._v(" Take a look at our courses at the "),_c('span',[_vm._v("University of Derby")]),_c('img',{staticClass:"ml-1",attrs:{"height":"15","src":require("@/assets/uod_favicon.png"),"alt":"University of Reading logo"}})])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }