<template lang="html">
	<div class="customContainer">
		<div class="leftPanel hidden-sm-and-down">
			<v-card class="cardBody mb-4">
				<v-card-title class="cardTitle title">
					<v-icon>pin_drop</v-icon>
					<span class="ml-2">Select Stripes</span>
				</v-card-title>
				<v-divider></v-divider>
				<region-select @endPointReached="regionSelected"></region-select>
			</v-card>

			<v-card class="cardBody" v-if="showStripes">
				<v-card-title class="cardTitle title">
					<v-icon>info</v-icon>
					<span class="ml-2">Information</span>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<table>
						<tbody>
							<tr>
								<td class="fieldTitle">Stripes</td>
								<td>{{ imageInfo.name }}</td>
							</tr>
							<tr>
								<td class="fieldTitle">Date Range</td>
								<td>
									{{ imageInfo.timeRange.from }}-{{ imageInfo.timeRange.to }}
								</td>
							</tr>
						</tbody>
					</table>
				</v-card-text>
				<v-card-text>
					<p class="data-use">
						<span
							class="data-use"
							v-if="$store.selectedRegion.indexOf('forest') < 0"
							><a
								href="https://creativecommons.org/licenses/by-sa/4.0/deed.en"
								target="_blank"
								>CC BY-SA 4.0</a
							>
							- Use of the biodiversity stripes is encouraged but must include
							appropriate acknowledgement of
							<a href="https://biodiversitystripes.info"
								>biodiversitystripes.info</a
							>
							and LPI 2024, Living Planet Index database. (<a
								href="https://www.livingplanetindex.org"
								target="_blank"
								>www.livingplanetindex.org</a
							>)<br
						/></span>
						<span
							class="data-use"
							v-if="$store.selectedRegion.indexOf('uk') >= 0"
							><br />UK stripes use public sector information from Defra, UK,
							Biodiversity Indicators 2021, licenced under the Open Government
							Licence v3.0
						</span>
						<span
							class="data-use"
							v-else-if="$store.selectedRegion.indexOf('forest') >= 0"
							><a
								href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1"
								target="_blank"
								>CC BY-NC-SA 4.0</a
							>
							- Creator and licensor WWF-UK. ZSL/WWF (2022)
							<a href="https://livingplanetindex.org/fsi" target="_blank"
								>Forest Specialist Index</a
							>, 1970 to 2018.<br /><br />The Forest Stripes are a collaboration
							between WWF, the University of Reading, University of Derby and
							ZSL, the Zoological Society of London, as part of the wider
							Climate Stripes family. biodiversitystripes.info /
							showyourstripes.info. Note that this product is not licenced for
							commercial use without written permission from WWF and ZSL.
						</span>
					</p>
				</v-card-text>
			</v-card>
		</div>

		<div class="rightPanel">
			<display-stripes
				v-if="showStripes"
				:imageSettings="imageInfo"
			></display-stripes>
			<region-select-small
				@endPointReached="regionSelected"
				class="hidden-md-and-up"
			></region-select-small>
		</div>
	</div>
</template>

<script>
import RegionSelect from './RegionSelect.vue'
import RegionSelectSmall from './RegionSelectSmall.vue'
import DisplayStripes from './DisplayStripes.vue'

export default {
	data() {
		return {
			// waits for the emit
			display_information_box: false,
			media: {},
		}
	},

	computed: {
		imageInfo() {
			const region = this.$route.params.region
				? this.$route.params.region
				: 'global'
			const regionObj = this.$store.menuTree[region]
			if (!regionObj) {
				// Menu tree probably hasn't loaded yet
				return {}
			}
			return regionObj
		},
		showStripes() {
			return this.imageInfo && this.imageInfo.hasOwnProperty('url')
		},
		playing() {
			return this.$store.playing
		},
	},
	methods: {
		regionSelected(media) {
			this.display_information_box = media.display
			this.media = media
		},
		getNextStripe() {
			const regions = Object.keys(this.$store.menuTree)
			let nextR, nextV
			for (let i = 0; i < regions.length; i++) {
				const region = regions[i]
				if (region === this.$store.selectedRegion) {
					const variants = Object.keys(this.$store.menuTree[region].variants)
					if (
						variants.length == 0 ||
						(variants.length == 1 && this.$store.selectedVariant == variants[0])
					) {
						let nextI = i + 1
						if (nextI >= regions.length) {
							nextI = 0
						}
						nextR = regions[nextI]
						nextV = ''
						break
					} else if (this.$store.selectedVariant == '') {
						nextR = region
						nextV = variants[0]
						break
					}

					for (let j = 0; j < variants.length; j++) {
						const variant = variants[j]
						if (variant === this.$store.selectedVariant) {
							let nextJ = j + 1
							if (nextJ >= variants.length) {
								let nextI = i + 1
								if (nextI >= regions.length) {
									nextI = 0
								}
								nextR = regions[nextI]
								nextV = ''
							} else {
								nextR = region
								nextV = variants[nextJ]
							}
							break
						}
					}
				}
			}
			const nextRegion = this.$store.menuTree[nextR]
			let url
			if (nextV && nextV !== '') {
				url = nextRegion.variants[nextV].url
			} else {
				url = nextRegion.url
			}
			return {
				url,
				region: nextR,
				variant: nextV,
			}
		},
		advanceToNextStripe() {
			if (this.$store.playing) {
				let { region, variant } = this.getNextStripe()
				const params = {
					region,
					variant,
				}
				this.$router.push({
					name: 'stripes',
					params,
				})
				this.countdown()
			}
		},
		countdown() {
			if (!this.$store.playing) {
				return
			}
			if (this.$store.countdown === 1) {
				this.$store.countdown = 6
				this.advanceToNextStripe()
			} else {
				this.$store.countdown -= 1
				setTimeout(this.countdown, 1000)
			}
		},
		setNextImageName() {
			const m = this.$store.menuTree
			let { region, variant } = this.getNextStripe()
			const nextRegion = m[region]
			let nextImage = `${nextRegion.name}`
			if (variant !== '' && nextRegion.variants.hasOwnProperty(variant)) {
				nextImage += ` with ${nextRegion.variants[variant].name}`
			}
			this.$store.nextImage = nextImage
		},
	},
	watch: {
		playing() {
			if (this.$store.playing) {
				this.countdown()
			}
		},
	},
	beforeRouteUpdate(to, from, next) {
		if (to.params.region !== this.$store.selectedRegion) {
			this.$store.selectedRegion = to.params.region || 'global'
		}
		if (to.params.variant !== this.$store.selectedVariant) {
			this.$store.selectedVariant = to.params.variant || ''
		}
		this.setNextImageName()
		next()
	},
	beforeRouteLeave(to, from, next) {
		this.$store.playing = false
		this.$store.countdown = 6
		next()
	},
	mounted() {
		this.setNextImageName()
		if (this.$store.playing) {
			this.countdown()
		}
	},
	components: {
		RegionSelect,
		RegionSelectSmall,
		DisplayStripes,
	},
}
</script>

<style lang="css" scoped>
.customContainer {
	width: 100%;
	margin: auto 0;
	display: flex;
	padding: 7vh 0;
}
.leftPanel {
	flex: 3;
	padding: 0 2vw 0 5vw;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}
.rightPanel {
	flex: 7;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 5vw 0 2vw;
}
.cardBody {
	max-width: 450px;
}
.fieldTitle {
	width: 120px;
}
.data-use {
	border: 1px solid lightgray;
	padding: 3px 12px;
	margin-bottom: 1.5rem;
	margin-top: 0;
}

.data-use span {
	border: none;
	padding: 0;
}
table {
	width: 100%;
}
td {
	padding: 3px 12px;
	border: 1px solid lightgray;
}
.tableTitle {
	text-align: center;
	margin-bottom: 3px;
	font-weight: bold;
	font-size: 15px;
}
a {
	color: darkslategray;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}
</style>
